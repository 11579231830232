/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { useLocation } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { DOMAIN, META_IMAGE, SOCIAL_LINKS } from '@shared/constants/constants';
import { LANGUAGES } from '@shared/constants/languages';

const origin = DOMAIN.mainUrl;

const getSchemaOrgJSON = () => [
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://atlasvpn.com',
    name: 'Atlas VPN',
    alternateName: 'Atlas VPN',
    description:
      'Atlas VPN is a free VPN app that ensures private browsing by changing your IP address and encrypting your connections.',
    logo: META_IMAGE,
    sameAs: [
      SOCIAL_LINKS.facebook,
      SOCIAL_LINKS.twitter,
      SOCIAL_LINKS.instagram,
      SOCIAL_LINKS.linkedin,
      SOCIAL_LINKS.youtube,
    ],
  },
];

const getPathWithLanguage = ({ language, originalPath }) =>
  originalPath === '/'
    ? `${origin}${language !== 'en' ? `/${language}` : '/'}`
    : `${origin}${language !== 'en' ? `/${language}` : ''}${originalPath}`;

const SEO = ({ description, meta = [], keywords = [], title, image, hideUrl }) => {
  const { pathname } = useLocation();
  const { languages: pageLanguages, language, originalPath } = useI18next();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      // titleTemplate={`%s - ${site.siteMetadata.title}`}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image?.startsWith('https://') ? image : origin + image,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image?.startsWith('https://') ? image : origin + image,
        },
      ]
        .concat(
          !hideUrl
            ? {
                property: `og:url`,
                content: `${site.siteMetadata.siteUrl}${pathname}`,
              }
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {/* Schema.org tags*/}
      <script type="application/ld+json">{JSON.stringify(getSchemaOrgJSON())}</script>

      {/* hreflang links */}
      {pageLanguages.length > 1 && [
        <link
          key="x-default"
          rel="alternate"
          href={`${origin}${originalPath}`}
          hrefLang="x-default"
        />,
        ...pageLanguages.map((language) => (
          <link
            key={language}
            rel="alternate"
            href={getPathWithLanguage({ language, originalPath })}
            hreflang={
              LANGUAGES.find((languageObj) => languageObj.value.toLowerCase() === language).value // to get right casing for languages like pt-BR
            }
          />
        )),
      ]}

      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/atlas-fav-icon-32px.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/atlas-fav-icon-48px.png`}
        sizes="48x48"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/atlas-fav-icon.png`}
        sizes="51x51"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/atlas-fav-icon-144px.png`}
        sizes="144x144"
      />
    </Helmet>
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
